import { ProductListSliceProps } from '@ui/slice-machine/slices/product-list-slice/ProductListSlice';
import dynamic from 'next/dynamic';

const ProductList = dynamic<ProductListSliceProps>(() =>
  import('@ui/slice-machine/slices/product-list-slice/ProductListSlice').then(
    (val) => val.ProductListSlice,
  ),
);

export default ProductList;
